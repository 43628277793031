import { module } from 'modujs';
import config from '../config';

const message = {
    en: 'Color copied to clipboard',
    fr: 'Couleur copié dans le clipboard'
}

console.log(config)

export default class Colors extends module {

    constructor(m) {
        super(m);

        this.events = {
            click: {
                'item': 'copyToClipboard'
            }
        }
    }

    init() {
    }

    copyToClipboard(e) {
        const color = e.curTarget.dataset.color

        const $el = document.createElement('textarea');
        $el.value = color;
        $el.setAttribute('readonly', '');
        $el.style.position = 'absolute';
        $el.style.left = '-9999px';
        document.body.appendChild($el);
        $el.select();
        document.execCommand('copy');
        document.body.removeChild($el);

        this.call('show', { text: message[config.LANG], duration: 3000 }, 'Notification')
    }
}
