import { module } from 'modujs';
import Vlitejs from 'vlitejs';

export default class extends module {
    constructor(m) {
        super(m);

        this.$el = this.el
        this.id = this.getData('id')
        this.poster = this.getData('poster') || null
    }

    init() {

        this.player = new Vlitejs(this.$el, {
            poster: this.poster,
        });
    }

    destroy() {
        this.player.destroy()
    }
}
