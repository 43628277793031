import { module } from 'modujs';
import config from '../config';

const regexEmail = /\S+@\S+\.\S+/
const regexPhone = /^\+(?:[0-9] ?){6,14}[0-9]$/

// Password strength :
// - 1 lowercase
// - 1 uppercase
// - 1 numeric
// - 8 characters minimum
const regexPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')

const errorMessages = {
    emptyField: {
        en: 'This field cannot be empty',
        fr: 'Ce champs est obligatoire'
    },
    invalideEmail: {
        en: 'Fill in a valid email address',
        fr: 'Renseigner un courriel valide'
    },
    invalidePhone: {
        en: 'Fill in a valid phone number',
        fr: 'Renseigner un numéro de téléphone valide'
    },
    invalidePassword: {
        en: 'The password must have at least 8 characters, including 1 lowercase letter, 1 uppercase letter and 1 number.',
        fr: 'Le mot de passe doit contenir 8 caractères minimum, une lettre en minuscule, une lettre en majuscule et un chiffre.'
    },
    differentPassword: {
        en: 'The password must be the same',
        fr: 'Le mot de passe doit être identique'
    },
}

export default class Form extends module {

    static get settings() {
        return {
            FIELD_SELECTOR: '[data-form="field"]',
            ERROR_SELECTOR: '[data-form="error"]',
            SUBMIT_CLASS: 'is-submitted',
            INPUT_ERROR_CLASS: 'has-error',
            STATE: {
                SUCCESS: 'success',
                ERROR: 'error',
            }
        }
    }

    constructor(m) {
        super(m);

        // DOM
        this.$el = this.el
        this.$inputs = Array.from(this.$('input'))
        this.$requiredInputs = this.$inputs.filter($input => $input.required)

        const $inputPasswords = this.$inputs.filter($input => $input.type === 'password')
        this.$inputPassword = $inputPasswords.length > 1 ? $inputPasswords[0] : null
        this.$inputPasswordConfirmation = $inputPasswords.length > 1 ? $inputPasswords[1] : null

        // Events
        this.$requiredInputs.forEach($input => {
            $input.addEventListener('input', this.onRequiredInputChange.bind(this))
        })

        this.events = {
            submit: 'onSubmit'
        }

        this.sitekey = typeof window.app !== 'undefined' && window.app.hasOwnProperty('recaptchaPublicKey')
            ? window.app.recaptchaPublicKey
            : false;
        this.useRecaptcha = this.getData('use-recaptcha') || false;
        this.badgeContainerId = 'grecaptcha-container-id';
    }

    onSubmit(e) {
        e.preventDefault();

        this.formAction = e.target.action;
        this.formData = this.getFormData(e.target);

        // Save submit event
        this.submitEvent = e;
        this.formData = this.getFormData(e.target);

        // TODO: form submition
        let hasError = false
        this.$requiredInputs.forEach($input => {

            // Detect empty required inputs
            if($input.value.trim() === '') {
                this.setStatus($input, errorMessages.emptyField[config.LANG], Form.settings.STATE.ERROR)
                hasError = true
            }
            // Validate email address
            else if ($input.type === 'email' && !regexEmail.test($input.value)) {
                this.setStatus($input, errorMessages.invalideEmail[config.LANG], Form.settings.STATE.ERROR)
                hasError = true
            }
            // Validate phone number
            else if($input.type === 'tel' && !regexPhone.test($input.value)) {
                this.setStatus($input, errorMessages.invalidePhone[config.LANG], Form.settings.STATE.ERROR)
                hasError = true
            }
            // Validate confirmation password
            else if($input === this.$inputPasswordConfirmation && this.$inputPasswordConfirmation.value !== this.$inputPassword.value) {
                this.setStatus($input, errorMessages.differentPassword[config.LANG], Form.settings.STATE.ERROR)
                hasError = true
            }
            // Validate password
            else if($input.type === 'password' && !regexPassword.test($input.value)) {
                this.setStatus($input, errorMessages.invalidePassword[config.LANG], Form.settings.STATE.ERROR)
                hasError = true
            }

            // Valid input
            else {
                this.setStatus($input, null, Form.settings.STATE.SUCCESS)
            }
        })

        if(hasError) {
            const $firstErrorTarget = this.$requiredInputs.find($input => $input.closest(Form.settings.FIELD_SELECTOR).classList.contains(Form.settings.INPUT_ERROR_CLASS))
            this.call('scrollTo', { target: `#${$firstErrorTarget.id}` }, 'Scroll')
        } else if (this.useRecaptcha && this.sitekey) {

            if (!window.hasRenderedRecaptcha) {
                this.clientId = grecaptcha.render(this.badgeContainerId, {
                    'sitekey': this.sitekey,
                    'theme': 'dark',
                    'size': 'invisible',
                    'badge': 'inline',
                });
                window.hasRenderedRecaptcha = true
            }

            grecaptcha.ready(() => {
                grecaptcha.execute(this.clientId)
                    .then(token => this.processForm(token));
            });
        } else {
            this.processForm()
        }
    }

    ///////////////
    // Methods
    ///////////////
    processForm(token) {
        // Check if form is busy
        this.$el.classList.add(Form.settings.SUBMIT_CLASS)

        if (token) {
            this.formData.append('g-recaptcha-response', token)
        }

        // From is processing

        try {
            this.submitForm();
        } catch (error) {
            console.error('[App.Form.handleSubmit]', error);
            // this.resumeForm();
        }
    }

    submitForm() {
        // Check if I can access to the submit event
        if (!this.submitEvent) {
            throw new Error('Missing submit event object');
        }

        // Prepare fetch
        const form = this.submitEvent.target;
        const formUrl = form.action;

        const controller = new AbortController();

        // Start fetch
        fetch(formUrl, {
            method: form.method,
            body: this.formData,
            signal: controller.signal

        }).then(response => response.json())
          .then(response => {
            // Success
            if (response && response.success) {

                const successMessage = this.$el.dataset.success
                console.log(successMessage)
                if(successMessage)  {
                    this.call('show', { text: successMessage, duration: 3000 }, 'Notification')
                }

                setTimeout(() => {
                    this.reset()
                }, 5000);

                if (response.redirectUrl) {
                    window.location = response.redirectUrl
                }

            }

            // Errors
            if (response && response.errors != '') {

            }

            // this.resumeForm();

        }).catch(error => {
            console.error('[App.Form.submitForm]', error);
            // this.resumeForm();
        })
    }

    setStatus($input, message, status) {
        console.log('setStatus', message, status)
        const $field = $input.closest(Form.settings.FIELD_SELECTOR)
        const $error = $field.querySelector(Form.settings.ERROR_SELECTOR)
        $error.innerHTML = message

        if (status === Form.settings.STATE.SUCCESS) {
            $field.classList.remove(Form.settings.INPUT_ERROR_CLASS)
        } else if (status === Form.settings.STATE.ERROR) {
            $field.classList.add(Form.settings.INPUT_ERROR_CLASS)
        }
    }

    onRequiredInputChange(e) {
        const $parent = e.target.closest(Form.settings.FIELD_SELECTOR)

        if($parent.classList.contains(Form.settings.INPUT_ERROR_CLASS)) {
            $parent.classList.remove(Form.settings.INPUT_ERROR_CLASS)
        }
    }

    reset() {
        this.$el.classList.remove(Form.settings.SUBMIT_CLASS)
        const changeEvent = new Event('change')

        this.$requiredInputs.forEach($input => {
            $input.removeEventListener('input', this.onRequiredInputChange)
        })

        this.$inputs.forEach($input => {
            $input.value = ''
            $input.dispatchEvent(changeEvent)
        })

        this.$requiredInputs.forEach($input => {
            $input.addEventListener('input', this.onRequiredInputChange.bind(this))
        })
    }

    getFormData(form) {
        const formData = new FormData(form);
        return formData;
    }

    destroy() {
        super.destroy();

        this.$requiredInputs.forEach($input => {
            $input.removeEventListener('input', this.onRequiredInputChange)
        })
    }
}
