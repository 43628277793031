import { module } from 'modujs';
import { html } from '../utils/environment';

export default class Content extends module {

    static get settings() {
        return {
            CLASS_ACTIVE: '-content-inview'
        }
    }

    constructor(m) {
        super(m);

        // DOM
        this.$el = this.el
        this.$blocks = Array.from(this.$('block'))

        this.activeBlockIndex = -1

        // Observer options
        this.options = {
            rootMargin: '50% 0% -50% 0%',
            threshold: 0
        }
    }

    init() {
        this.resize()

        window.addEventListener('resizeEnd', this.onResize = () => this.resize())
        window.addEventListener('scroll', this.onScroll = () => this.scroll())
    }

    scroll() {
        const index = this.blocksRect.findIndex(blockRect => (window.scrollY > blockRect.top && window.scrollY < blockRect.top + blockRect.height))

        this.toggleActiveBlock(index)
    }

    resize() {
        let rect
        this.blocksRect = this.$blocks.map($block => {
            rect = $block.getBoundingClientRect()
            return {
                top: rect.top,
                height: rect.height,
            }
        })

        this.scroll()
    }

    toggleActiveBlock(index) {

        if(index === this.activeBlockIndex) {
            return
        }

        this.activeBlockIndex = index
        this.toggleColor(this.activeBlockIndex >= 0)
        this.call('toggleItem', index, 'Nav')
    }

    toggleColor(apply=true) {
        if(apply) {
            html.classList.add(Content.settings.CLASS_ACTIVE)
        } else {
            html.classList.remove(Content.settings.CLASS_ACTIVE)
        }
    }

    destroy() {
        this.observer.unobserve(this.$el)
    }
}
