import { module } from 'modujs';
import { html } from '../utils/environment';

export default class Nav extends module {

    static get settings() {
        return {
            CLASS_OPEN: 'has-nav-open',
            CLASS_ACTIVE_ITEM: 'is-active'
        }
    }

    constructor(m) {
        super(m);

        // Vars
        this.isOpen = false
        this.$items = Array.from(this.$('item'))
        this.activeItemIndex = -1

        // Events
        this.events = {
            click: {
                toggler: 'toggle',
                link: 'close'
            }
        }
    }

    init() {
    }

    toggle() {

        if(this.isOpen) {
            this.close()
        } else {
            this.open()
        }
    }

    open() {

        if(this.isOpen) {
            return
        }

        html.classList.add(Nav.settings.CLASS_OPEN)
        this.isOpen = true
    }

    close() {

        if(!this.isOpen) {
            return
        }

        html.classList.remove(Nav.settings.CLASS_OPEN)
        this.isOpen = false
    }

    toggleItem(index) {

        if(this.activeItemIndex > -1 && this.activeItemIndex !== index) {
            this.$items[this.activeItemIndex].classList.remove(Nav.settings.CLASS_ACTIVE_ITEM)
        }

        if(index > -1) {
            this.$items[index].classList.add(Nav.settings.CLASS_ACTIVE_ITEM)
        }

        this.activeItemIndex = index
    }
}
