import { html } from './utils/environment';
// import isMobile from './utils/isMobile'

const env = process.env.NODE_ENV

// const isMobileObj = isMobile()

export default config = Object.freeze({
    // Environments
    ENV: env,
    IS_DEV: env === 'development',
    IS_PROD: env === 'production',

    // // Device
    // IS_TOUCH: isMobileObj?.phone || isMobileObj?.tablet,

    LANG: html.getAttribute('lang'),

    // CSS class names
    CSS_CLASS: {
        // FONTS_LOADED: 'has-fonts-loaded',
        // IMAGES_PRELOADED: 'has-images-preloaded',
        // LAZY_LOADED: 'is-lazy-loaded',
        LOADED: 'is-loaded',
        LOADING: 'is-loading',
        READY: 'is-ready',
        // INVIEW: 'is-inview'
    },

    // JS selectors
    SELECTORS: {
        // IMAGE_LAZY: '.c-lazy',
        // IMAGE_PRELOAD: 'img[data-preload]',
        MAIN_STYLESHEET: '#main-css',
    }
})
