import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.bindScrollTo()

        // Initial scroll
        const hash = document.URL.split('#')
        if(hash.length > 1) {
            this.scrollTo({ target: `#${hash[1]}`})
        }
    }

    bindScrollTo() {
        let target
        this.scrollTargetsEvents = []
        this.$scrollTargets = Array.from(document.querySelectorAll('a[href^="#"]'))

        this.$scrollTargets.forEach((button, i) => {
            this.scrollTargetsEvents[i] = e => {
                e.preventDefault()
                target = `#${button.getAttribute('href').split('#')[1]}`
                this.scrollTo({ target })
            }

            button.addEventListener('click', this.scrollTargetsEvents[i])
        })
    }

    unbindScrollTo() {

        if(this.$scrollTargets.length > 0) {
            this.$scrollTargets.forEach((target, i) => {
                target.removeEventListener('click', this.scrollTargetsEvents[i])
            })
        }
    }

    scrollTo(args) {

        // Return if no target specified
        if(typeof args.target === 'undefined') {
            return
        }

        // Scroll to animation
        document.querySelector(args.target).scrollIntoView({
            behavior: 'smooth'
        })
        // window.scrollTo({
        //     top: document.querySelector(args.target).offsetTop,
        //     behavior: 'smooth'
        // })

        // Close Menu
        this.call('close', null, 'Nav')
    }

    destroy() {
        this.unbindScrollTo()
    }
}
