import { module } from 'modujs';

export default class Notification extends module {

    static get settings() {
        return {
            CLASS_VISIBLE: 'is-visible',
        }
    }

    constructor(m) {
        super(m);

        // DOM
        this.$el = this.el
        this.$content = this.$('content')[0]

        this.isVisible = false
    }

    init() {
        console.log('init')
    }

    show(args) {
        let delay = 0

        if(this.isVisible) {
            this.hide()
            delay = 300
        }

        setTimeout(() => {

            if(args.text) {
                this.$content.innerHTML = args.text
            }

            this.$el.classList.add(Notification.settings.CLASS_VISIBLE)

            this.isVisible = true

            if(args.duration) {

                setTimeout(() => {
                    this.hide()
                }, args.duration);
            }
        }, delay)
    }

    hide() {

        if(!this.isVisible) {
            return
        }

        this.$el.classList.remove(Notification.settings.CLASS_VISIBLE)

        setTimeout(() => {
            this.$content.innerHTML = ''
            this.isVisible = false
        }, 300);
    }
}
