import { module } from 'modujs';

export default class FormInputText extends module {

    static get settings() {
        return {
            FILLED_CLASS: 'is-filled'
        }
    }

    constructor(m) {
        super(m);

        this.el.addEventListener('change', this.onInput = () => this.change())
    }

    init() {
    }

    change() {

        if(this.el.value !== '') {
            this.el.classList.add(FormInputText.settings.FILLED_CLASS)
        } else {
            this.el.classList.remove(FormInputText.settings.FILLED_CLASS)
        }
    }

    destroy() {
        super.destroy();
        this.el.removeEventListener('change', this.onInput)
    }
}
